<template>
  <div id="fillstroke">
    <el-form @submit.native.prevent ref="form">
      <el-collapse v-model="activeNames">
        <el-collapse-item v-if="chartType !== 'heatmap'" title="Fill" name="0">
          <div class="mb3">
            <h4 class="mt1">Opacity</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="fill.opacity"
                :step="0.1"
                :max="1"
                :min="0"
                show-input
              ></el-slider>
            </div>
          </div>

          <el-form-item label="Fill Type">
            <el-radio-group
              @change="updateChart"
              v-model="fill.type"
              size="small"
            >
              <el-radio-button label="solid"></el-radio-button>
              <el-radio-button label="gradient"></el-radio-button>
              <el-radio-button label="pattern"></el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-collapse class="mt3" v-if="fill.type === 'gradient'">
            <el-collapse-item title="Gradient Options">
              <el-form-item
                v-if="fill.gradient.colorStops.length === 0"
                label="Shade"
              >
                <el-radio-group
                  @change="updateChart"
                  v-model="fill.gradient.shade"
                  size="small"
                >
                  <el-radio-button label="light"></el-radio-button>
                  <el-radio-button label="dark"></el-radio-button>
                </el-radio-group>
              </el-form-item>

              <el-form-item class="mt2" label="Direction">
                <el-radio-group
                  @change="updateChart"
                  v-model="fill.gradient.type"
                  size="small"
                >
                  <el-radio-button label="vertical">
                    <i class="el-icon-bottom"></i>
                  </el-radio-button>
                  <el-radio-button label="horizontal">
                    <i class="el-icon-right"></i>
                  </el-radio-button>
                  <el-radio-button label="diagonal">
                    <i class="el-icon-bottom-right"></i>
                  </el-radio-button>
                  <el-radio-button label="diagonal2">
                    <i class="el-icon-bottom-left"></i>
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>

              <div v-if="fill.gradient.colorStops.length === 0">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Inverse the gradient direction"
                  placement="left-start"
                >
                  <el-form-item label="Inverse Direction">
                    <el-switch
                      @change="updateChart"
                      inactive-color="#24272b"
                      v-model="fill.gradient.inverseColors"
                    ></el-switch>
                  </el-form-item>
                </el-tooltip>

                <h4 class="mt1">Shade Intensity</h4>
                <div class="block">
                  <el-slider
                    @change="updateChart"
                    v-model="fill.gradient.shadeIntensity"
                    :step="0.1"
                    :max="1"
                    :min="0"
                  ></el-slider>
                </div>

                <el-form-item
                  label="Gradient To Colors"
                  :class="{ 'custom-colors-picker': true }"
                >
                  <extended-el-color-picker
                    v-for="(color, index) in fill.gradient.gradientToColors"
                    :key="index"
                    @change="
                      (val) => {
                        updateGradientToColors(val, index);
                      }
                    "
                    :value="color"
                  ></extended-el-color-picker>
                  <el-button
                    @click="appendGradientToColor"
                    size="mini"
                    class="ml2"
                  >
                    <i class="el-icon-plus"></i>
                  </el-button>
                  <el-button
                    @click="clearGradientToColors"
                    size="mini"
                    title="Delete All Colors"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </el-form-item>

                <h4 class="mt1">Gradient From Opacity</h4>
                <div class="block">
                  <el-slider
                    @change="updateChart"
                    v-model="fill.gradient.opacityFrom"
                    :step="0.1"
                    :max="1"
                    :min="0"
                    show-input
                  ></el-slider>
                </div>

                <h4 class="mt1">Gradient To Opacity</h4>
                <div class="block">
                  <el-slider
                    @change="updateChart"
                    v-model="fill.gradient.opacityTo"
                    :step="0.1"
                    :max="1"
                    :min="0"
                    show-input
                  ></el-slider>
                </div>
              </div>

              <el-collapse class="mt3">
                <el-collapse-item title="Gradient Stops">
                  <div
                    v-for="(color, index) in fill.gradient.colorStops"
                    :key="index"
                  >
                    <el-form-item
                      class="mt1 custom-colors-picker"
                      label="Color"
                    >
                      <extended-el-color-picker
                        @change="updateChart"
                        v-model="fill.gradient.colorStops[index].color"
                      ></extended-el-color-picker>
                      <el-button
                        @click="deleteColorStop(index)"
                        size="mini"
                        type="danger"
                        title="Delete All Colors"
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </el-form-item>
                    <h4>Offset</h4>
                    <div class="block">
                      <el-slider
                        @change="updateChart"
                        v-model="fill.gradient.colorStops[index].offset"
                        :max="100"
                        :min="0"
                        show-input
                      ></el-slider>
                    </div>
                    <h4>Opacity</h4>
                    <div class="block">
                      <el-slider
                        @change="updateChart"
                        v-model="fill.gradient.colorStops[index].opacity"
                        :max="1"
                        :min="0"
                        :step="0.1"
                        show-input
                      ></el-slider>
                    </div>

                    <el-divider></el-divider>
                  </div>
                  <el-button
                    @click="appendColorStop"
                    type="primary"
                    size="mini"
                    class="mt3 float-right"
                  >
                    <i class="el-icon-plus"></i>
                  </el-button>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>

          <el-collapse class="mt3" v-if="fill.type === 'pattern'">
            <el-collapse-item title="Pattern Options">
              <el-form-item label="Type">
                <el-radio-group
                  @change="updateChart"
                  v-model="fill.pattern.style"
                  size="small"
                >
                  <el-radio-button label="verticalLines">||</el-radio-button>
                  <el-radio-button label="horizontalLines">=</el-radio-button>
                  <el-radio-button label="slantedLines">\\</el-radio-button>
                  <el-radio-button label="squares">+</el-radio-button>
                  <el-radio-button label="circles">o</el-radio-button>
                </el-radio-group>
              </el-form-item>

              <h4>Pattern Width</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="fill.pattern.width"
                  :max="50"
                  :min="0"
                  show-input
                ></el-slider>
              </div>

              <h4>Pattern Height</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="fill.pattern.height"
                  :max="50"
                  :min="0"
                  show-input
                ></el-slider>
              </div>

              <h4>Pattern Border Width</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="fill.pattern.strokeWidth"
                  :max="50"
                  :min="0"
                  show-input
                ></el-slider>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>

        <el-collapse-item title="Stroke / Outline" name="1">
          <el-form-item
            v-if="chartType !== 'line' && chartType !== 'radialBar'"
            label="Show"
          >
            <el-switch
              inactive-color="#24272b"
              @change="updateChart"
              v-model="stroke.show"
            ></el-switch>
          </el-form-item>

          <div v-if="chartType !== 'radialBar'">
            <el-form-item class="custom-colors-picker" label="Line Colors">
              <extended-el-color-picker
                v-for="(color, index) in stroke.colors"
                :key="index"
                @change="
                  (val) => {
                    updateStrokeColors(val, index);
                  }
                "
                :value="color"
              ></extended-el-color-picker>
              <el-button @click="appendStrokeColor" size="mini">
                <i class="el-icon-plus"></i>
              </el-button>
              <el-button
                @click="clearAllStrokeColors"
                size="mini"
                title="Delete All Colors"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </el-form-item>
          </div>

          <el-form-item
            v-if="chartType === 'line' || chartType === 'area'"
            label="Line Style"
          >
            <el-radio-group
              @change="updateChart"
              v-model="stroke.curve"
              size="small"
            >
              <el-radio-button label="smooth"></el-radio-button>
              <el-radio-button label="straight"></el-radio-button>
              <el-radio-button label="stepline"></el-radio-button>
            </el-radio-group>
          </el-form-item>

          <div v-if="chartType !== 'radialBar'">
            <h4>Line Width</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="stroke.width"
                :max="30"
                :min="0"
                show-input
              ></el-slider>
            </div>
          </div>

          <el-form-item class="mt2" label="Line Cap">
            <el-radio-group
              @change="updateChart"
              v-model="stroke.lineCap"
              size="small"
            >
              <el-radio-button label="round"></el-radio-button>
              <el-radio-button label="butt"></el-radio-button>
              <el-radio-button label="square"></el-radio-button>
            </el-radio-group>
          </el-form-item>

          <h4>Line Dash Array</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="stroke.dashArray"
              :max="30"
              :min="0"
              show-input
            ></el-slider>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ["0", "1"],

      stroke: {
        show: true,
        curve: "smooth", // "smooth" / "straight" / "stepline"
        lineCap: "butt", // round, butt , square
        colors: undefined,
        width: 2,
        dashArray: 0, // single value or array of values
      },
      fill: {
        type: "solid",
        colors: undefined,
        opacity: 0.85,
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
        pattern: {
          style: "squares", // String | Array of Strings
          width: 6,
          height: 6,
          strokeWidth: 2,
        },
      },
    };
  },
  watch: {
    chartConfig: function(newProps) {
      this.setNewOptions(newProps);
    },
  },
  mounted: function() {
    const opts = this.getChartConfig().w.config;
    this.setNewOptions(opts);
  },
  methods: {
    setNewOptions(opts) {
      const f = opts.fill;
      const s = opts.stroke;
      this.stroke = {
        show: s.show,
        curve: s.curve, // "smooth" / "straight" / "stepline"
        lineCap: s.lineCap, // round, butt , square
        colors: s.colors,
        width: s.width,
        dashArray: s.dashArray,
      };
      this.fill = {
        type: f.type,
        colors: f.colors,
        opacity: f.opacity,
        gradient: {
          shade: f.gradient.shade,
          type: f.gradient.type,
          shadeIntensity: f.gradient.shadeIntensity,
          gradientToColors: f.gradient.gradientToColors,
          inverseColors: f.gradient.inverseColors,
          opacityFrom: f.gradient.opacityFrom,
          opacityTo: f.gradient.opacityTo,
          stops: f.gradient.stops,
          colorStops: f.gradient.colorStops,
        },
        pattern: {
          style: f.pattern.style,
          width: f.pattern.width,
          height: f.pattern.height,
          strokeWidth: f.pattern.strokeWidth,
        },
      };
    },
    updateStrokeColors(color, index) {
      Vue.set(this.stroke.colors, index, color);
      this.updateChart();
    },
    appendStrokeColor() {
      if (!this.stroke.colors) {
        this.stroke.colors = [];
      }
      this.stroke.colors.push("#fff");
      this.updateChart();
    },
    clearAllStrokeColors() {
      this.stroke.colors = undefined;
      this.updateChart();
    },
    appendColorStop() {
      let i = 1;
      if (this.fill.gradient.colorStops.length === 0) {
        i = 2;
      }

      for (let j = 0; j < i; j++) {
        this.fill.gradient.colorStops.push({
          opacity: 1,
          offset: j === 0 ? 0 : 100,
          color: j === 0 ? "#008ffb" : "#775dd0",
        });
      }
      this.updateChart();
    },
    deleteColorStop(index) {
      this.fill.gradient.colorStops.splice(index, 1);
      this.updateChart();
    },
    updateGradientToColors(color, index) {
      Vue.set(this.fill.gradient.gradientToColors, index, color);
      this.updateChart();
    },
    appendGradientToColor() {
      if (!this.fill.gradient.gradientToColors) {
        this.fill.gradient.gradientToColors = [];
      }
      this.fill.gradient.gradientToColors.push("#fff");
      this.updateChart();
    },
    clearGradientToColors() {
      this.fill.gradient.gradientToColors = undefined;
      this.updateChart();
    },
    updateChart: function() {
      const s = this.stroke;
      const f = this.fill;
      this.updateChartOptions({
        stroke: {
          show: s.show,
          curve: s.curve, // "smooth" / "straight" / "stepline"
          lineCap: s.lineCap, // round, butt , square
          colors: s.colors,
          width: s.width,
          dashArray: s.dashArray,
        },
        fill: {
          type: f.type,
          colors: f.colors,
          opacity: f.opacity,
          gradient: {
            shade: f.gradient.shade,
            type: f.gradient.type,
            shadeIntensity: f.gradient.shadeIntensity,
            gradientToColors: f.gradient.gradientToColors,
            inverseColors: f.gradient.inverseColors,
            opacityFrom: f.gradient.opacityFrom,
            opacityTo: f.gradient.opacityTo,
            stops: f.gradient.stops,
            colorStops: f.gradient.colorStops,
          },
          pattern: {
            style: f.pattern.style,
            width: f.pattern.width,
            height: f.pattern.height,
            strokeWidth: f.pattern.strokeWidth,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
